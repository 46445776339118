import React from "react";

import { theme } from "../../settings/theme.styles";
import {
  SectionMarkerContainer,
  SectionMarkerLine,
  SectionMarkerTextWrapper,
  SectionMarkerText,
} from "./section-marker.styles";

const SectionMarkerComponent = ({
  number,
  gradientOriginColor = theme.colors.secondary,
  gradientFinishColor = theme.colors.primary,
  children,
  ...otherProps
}) => {
  return (
    <SectionMarkerContainer {...otherProps}>
      <SectionMarkerLine
        gradientStart={gradientOriginColor}
        gradientEnd={gradientFinishColor}
      />
      <SectionMarkerTextWrapper
        background={gradientOriginColor}
        font={gradientFinishColor}
      >
        {number}
      </SectionMarkerTextWrapper>
      <SectionMarkerText color={gradientOriginColor}>
        {children}
      </SectionMarkerText>
    </SectionMarkerContainer>
  );
};

export default SectionMarkerComponent;
