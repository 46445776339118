import styled from "styled-components";
import { styled as materialStyled } from "@mui/material/styles";

import { mediaSizes } from "../../settings/theme.styles";
import { Button } from "@mui/material";

export const FormContainer = styled.form`
  display: grid;
  gap: 1em;
  justify-items: center;
  align-items: center;

  width: 100%;
  margin: 0 auto;
  > * {
    width: 100%;
  }
  
  > :nth-child(4) {
    margin-top: 2em;
    max-width: fit-content;
    height: 3em;
  }
  > :nth-child(5) {
    margin-top: 2em;
    max-width: fit-content;
    height: 3em;
  }

  @media (min-width: ${mediaSizes.md}) {
    grid-template-columns: 1fr 1fr;
    justify-items: end;
    max-width: 45em;

    > :nth-child(3) {
      grid-column: span 2;
    }
    > :nth-child(4){
      justify-self: start;
    }

  }
`;

export const StyledButton = materialStyled(Button)({
  borderRadius: "11px",
  paddingBlock: ".86em",

  maxWidth: "50%",

  textTransform: "capitalize",
  right: "0",
});
