import styled from "styled-components";

export const CustomButtonStyled = styled.button`
  position: relative;
  background-color: ${(props) => props.theme.colors.secondary};
  border-radius: 10px;
  border: none;
  color: white;
  cursor: pointer;

  width: 13rem;
  padding: 0.9em 0;

  font-size: 1em;
  font-family: ${(props) => props.theme.font.familyNeueMachina};
  font-weight: bold;

  z-index: 10;

  box-shadow: 2px 2px #0039a8;

  :active {
    box-shadow: -12px -12px #0039a8;
  }
`;
