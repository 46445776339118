import React from "react";
import { useTranslation } from "react-i18next";

import { FormContainer, StyledButton } from "./contact-us-form.styles";

import { CustomTextInputComponent } from "..";

const ContactUsFormComponent = () => {
  const { t } = useTranslation();

  return (
    <FormContainer action={process.env.REACT_APP_CONTACT_EMAIL} method="POST">
      <CustomTextInputComponent
        label={t("contact_us.label_name")}
        name={"name"}
      />
      <CustomTextInputComponent
        label={t("contact_us.label_phone")}
        name={"phone"}
      />
      <CustomTextInputComponent
        label={t("contact_us.label_email")}
        name={"email"}
        type={"email"}
      />
      <CustomTextInputComponent
        label={t("contact_us.label_subject")}
        name={"_subject"}
      />
      <CustomTextInputComponent
        label={t("contact_us.label_message")}
        name={"text"}
        type={'textarea'}
      />
      <StyledButton type="submit" variant="contained" color="primary">
        <strong>{t("contact_us.submit_btn")}</strong>
      </StyledButton>
    </FormContainer>
  );
};

export default ContactUsFormComponent;
