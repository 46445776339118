import styled, { css } from "styled-components";
import { mediaSizes } from "../../settings/theme.styles";

const TextStyles = css`
  font-size: calc(0.4em + 0.7vw);
  font-weight: bold;
  font-family: ${(props) => props.theme.font.familyNeueMachina};
  color: ${(props) => props.theme.colors.dark};

  @media (max-width: ${mediaSizes.md}) {
    font-size: calc(1.5em);
  }
`;

// elements
export const NavBarContainer = styled.nav`
  display: flex;
  justify-content: space-around;
  background-color: transparent;

  width: 100%;
  padding-top: 25px;
  height: calc(6vh + 2vw);

  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
`;

export const OptionsContainer = styled.div`
  display: ${(props) => (props.hidden ? "none" : "flex")};
  align-items: center;
  justify-content: center;

  width: fit-content;
  height: 100%;
  margin-left: 0.5vw;
  margin-right: 0.5vw;
  gap: 0.5vw;
`;

export const OptionDiv = styled.div`
  padding: 0 0.7vw;
  cursor: pointer;
  ${TextStyles};
`;

export const ContactUsButton = styled.button`
  display: inline-flex;
  align-items: center;
  justify-content: center;

  height: 3em;
  width: 8em;
  padding-block: 1em;
  padding-inline: calc(3em + 0.8vw);

  border: 2px solid;
  border-radius: 13px;

  font-weight: bold;

  background-color: transparent;

  ${TextStyles};
  font-size: clamp(0.3em, calc(0.3em + 0.9vw), 4em);

  @media (max-width: ${mediaSizes.md}) {
    border: 3px solid;
    border-radius: 17px;
  }
`;

export const LanguageButtonText = styled.span`
  ${TextStyles};
  text-transform: capitalize;
  padding-inline: 0;
  margin-inline: 0;

  color: ${props => props.currentLanguage ? props.theme.colors.dark : 'grey'};;
`;
