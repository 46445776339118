import React from "react";

import { theme } from "../../settings/theme.styles";
import {
  ContentWrapper,
  DrawerContainer,
  StyledDrawer,
} from "./custom-drawer.styles";

import TitleComponent from "../title/title.component";

const CustomDrawerComponent = ({
  drawerIdx,
  openDrawer,
  openSetter,
  title,
  children,
}) => {
  const isOpen = () => {
    return openDrawer === drawerIdx;
  };

  const toggleOpen = () => {
    if (isOpen()) {
      console.log('ispop');
      openSetter(0);
    } else {
      openSetter(drawerIdx);
    }
  };

  return (
    <DrawerContainer>
      <StyledDrawer>
        <TitleComponent color={theme.colors.light} content={title} />
        <TitleComponent
          color={isOpen() ? theme.colors.secondary : theme.colors.light}
          content={isOpen() ? "-" : "+"}
          onClick={toggleOpen}
        />
      </StyledDrawer>
      <ContentWrapper isOpen={isOpen()}>{children}</ContentWrapper>
    </DrawerContainer>
  );
};

export default CustomDrawerComponent;
