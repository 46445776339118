import React from "react";

import { theme } from "../../settings/theme.styles";
import { StyledParagraph } from "./paragraph.styles";

const ParagraphComponent = ({
  fontWeight = "bolder",
  color = theme.colors.light,
  children,
  ...otherProps
}) => {
  return (
    <StyledParagraph color={color} fontWeight={fontWeight} {...otherProps}>
      {`${children}`}
    </StyledParagraph>
  );
};

export default ParagraphComponent;
