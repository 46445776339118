import styled from "styled-components";
import { mediaSizes } from "../../settings/theme.styles";

export const SectionContainer = styled.div`
  position: relative;

  background-image:${props => props.backgroundImg?`url(${props.backgroundImg})`:'none'}; 
  
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;

  z-index: ${(props) => props.zIndex};

  padding-inline: 4vw;
  height: fit-content;
  width: 100vw;

  margin-top: -13em;
  padding-top: 13em;
  padding-bottom: 5em;
  @media (min-width: ${mediaSizes.lg}) {
    padding-inline: 13em;
  }
  @media (min-width: ${mediaSizes.xl}) {
    margin-top: -15em;
  }
  @media (min-width: ${mediaSizes.xxl}) {
  }

  > * {
    margin-bottom: 3rem;
  }
`;
