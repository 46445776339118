import React from "react";
import { useCustomMediaQuery } from "../../utils/hooks";
import { useTranslation } from "react-i18next";

import UpArrow from "../../assets/icons/Footer Up2x.png";
import { logos } from "../../settings/theme.styles";
import {
  ElementWrapper,
  FooterContainer,
  OptionDiv,
  OptionsContainer,
} from "./footer.styles";

import { HashLink } from "react-router-hash-link";
import { LogoContanierComponent, ParagraphComponent } from "../";
import { Link } from "react-router-dom";

const FooterComponent = () => {
  const { t } = useTranslation();
  const isScreenLg = useCustomMediaQuery("lg");

  return (
    <FooterContainer>
      <ElementWrapper>
        <LogoContanierComponent logoUrl={logos.footer_logo} />
      </ElementWrapper>
      <ElementWrapper>
        <OptionsContainer>
          <div>
            <HashLink smooth to="/home#home">
              <OptionDiv>{t("sections.home")}</OptionDiv>
            </HashLink>
            <HashLink smooth to="/home#benefits">
              <OptionDiv>{t("sections.benefits")}</OptionDiv>
            </HashLink>
          </div>
          <div>
            <HashLink smooth to="/home#about_us">
              <OptionDiv>{t("sections.about_us")}</OptionDiv>
            </HashLink>
            <HashLink smooth to="/home#strategy">
              <OptionDiv>{t("sections.strategy")}</OptionDiv>
            </HashLink>
          </div>
          <div>
            <HashLink smooth to="/home#services">
              <OptionDiv>{t("sections.services")}</OptionDiv>
            </HashLink>
            <HashLink smooth to="/home#startup">
              <OptionDiv>{t("sections.startups")}</OptionDiv>
            </HashLink>
          </div>
          <div>
            <Link to="/jobs">
              <OptionDiv>{t("sections.job")}</OptionDiv>
            </Link>
            <HashLink smooth to="/home#contact_us">
              <OptionDiv>{t("sections.contact_us")}</OptionDiv>
            </HashLink>
          </div>
        </OptionsContainer>
      </ElementWrapper>
      <ElementWrapper style={{ display: `${isScreenLg ? "block" : "none"}` }}>
        <HashLink smooth to="/#top">
          <img
            style={{
              width: "2.5em",
              justifySelf: "flex-end",
            }}
            src={UpArrow}
            alt="mouse icon"
          />
        </HashLink>
      </ElementWrapper>
      <ElementWrapper>
        <ParagraphComponent fontWeight={"lighter"}>
          {t("footer.email")}
        </ParagraphComponent>
      </ElementWrapper>
    </FooterContainer>
  );
};

export default FooterComponent;
