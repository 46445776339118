import styled from "styled-components";

export const LogoContainer = styled.div`
  display: ${(props) => (props.hidden ? "none" : "block")};

  border: none;
  width: fit-content;
  height: auto;
  align-self: center;

  cursor: ${(props) => (props.url === "#" ? "auto" : "pointer")}; ;
`;
