import React, { Suspense } from "react";
import ReactDOM from "react-dom/client";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import { ThemeProvider } from "styled-components";
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import HttpApi from "i18next-http-backend";
import { initOptions } from "./settings/i18next.config";

import "./index.css";
import "./fonts/NeueMachina/NeueMachina.css";
import "./fonts/Inter/Inter.css";
import { theme } from "./settings/theme.styles";

import App from "./App";
import { SpinnerComponent } from "./components";

i18n.use(initReactI18next).use(LanguageDetector).use(HttpApi).init(initOptions);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Suspense fallback={<SpinnerComponent />}>
    <BrowserRouter>
      <ThemeProvider theme={theme}>
        <React.StrictMode>
          <App />
        </React.StrictMode>
      </ThemeProvider>
    </BrowserRouter>
  </Suspense>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
