import styled from "styled-components";

export const DropdownMenuContainer = styled.div`
  background: rgb(175, 197, 239);
  background: radial-gradient(
    circle,
    rgba(175, 197, 239, 1) 0%,
    rgba(255, 255, 255, 1) 100%
  );
`;

export const DropdownMenuButton = styled.button`
  border: none;
  width: fit-content;
  height: fit-content;
  background: transparent;
`;

export const DropdownMenuItem = styled.div`
  background-color: transparent;
  margin: 2px;
  font-size: 2.5em;

  grid-column: span 2;
`;

export const DropdownMenu = styled.div`
  display: ${(props) => (props.show === true ? "grid" : "none")};
  grid-template-columns: 1fr 1fr;
  gap: 0.5em;
  > :first-child {
    width: 55px !important;
    height: auto;
    }
  & ${DropdownMenuItem}:nth-child(10) {
    justify-self: center;
    grid-column: span 1;
  }
  & ${DropdownMenuItem}:nth-child(11) {
    grid-column: span 1;
    font-size: 1em;
  }
  & ${DropdownMenuButton} {
    justify-self: end;
  }
  > * {
    align-self: center;
  }

  position: absolute;
  width: 100vw;
  height: fit-content;
  top: 0;
  right: 0;
  padding: 2vh 6vw;

  background: rgb(175, 197, 239);
  background: radial-gradient(
    circle,
    rgba(175, 197, 239, 1) 0%,
    rgba(255, 255, 255, 1) 100%
  );
`;
