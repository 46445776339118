import React, { useState } from "react";

import { theme } from "../../settings/theme.styles";
import {
  StyledLabel,
  StyledPlaceholder,
  StyledTextField,
} from "./custom-text-input.styles";

const CustomTextInputComponent = ({
  label,
  onChange,
  value,
  color = theme.colors.light,
  name = label,
  type = "text",
  ...otherProps
}) => {
  const [isDirty, setIsDirty] = useState(false);

  const handleOnBlur = (e) => {
    if (e.target.value.length > 0) {
      setIsDirty(true);
    } else {
      setIsDirty(false);
    }
  };

  return (
    <StyledLabel>
      <StyledTextField
        name={name}
        type={type}
        value={value}
        onChange={onChange}
        color={color}
        onBlur={handleOnBlur}
        {...otherProps}
      />
      <StyledPlaceholder isDirty={isDirty} color={color}>
        {label}
      </StyledPlaceholder>
    </StyledLabel>
  );
};

export default CustomTextInputComponent;
