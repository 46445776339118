import styled from "styled-components";
import { styled as materialStyled } from "@mui/material/styles";
import { Button } from "@mui/material";

export const JoinFormPresetContainer = styled.form`
  display: flex;
  justify-content: space-between;
  align-items: center;

  padding-top: 8em;
  > * {
    height: 4em;
  }
`;

export const StyledButton = materialStyled(Button)({
  borderRadius: "11px",
  paddingBlock: ".86em",

  maxWidth: "50%",

  textTransform: "capitalize",
});
