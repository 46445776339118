import styled from "styled-components";

export const SectionMarkerContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;
export const SectionMarkerLine = styled.div`
  height: 120px;
  width: 5px;
  margin: 0;

  background: ${(props) => props.gradientStart};
  background: linear-gradient(
    0deg,
    ${(props) => props.gradientStart} 0%,
    ${(props) => props.gradientEnd} 100%
  );
`;
export const SectionMarkerTextWrapper = styled.div`
  margin: 0;
  width: 30px;
  height: 30px;
  padding: 1.1rem 1.1rem 1.1rem 1.1rem;
  border-radius: 50px;

  font-family: ${(props) => props.theme.font.familyNeueMachina};
  font-size: 1.4em;
  font-weight: bold;

  background-color: ${(props) => props.background};
  color: ${(props) => props.font};

  display: flex;
  align-items: center;
  justify-content: center;
`;

export const SectionMarkerText = styled.h1`
  font-family: ${(props) => props.theme.font.familyNeueMachina};
  color: ${(props) => props.color};
  letter-spacing: .5rem;
  text-transform: uppercase;
  font-weight: bolder;
  margin-top: 50px;
`;
