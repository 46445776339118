import styled from "styled-components";
import { mediaSizes } from "../../settings/theme.styles";

export const VideoPlayerContainer = styled.div`
  background-color: ${(props) => props.theme.colors.primary};
  border-radius: 30px;
  border: solid 2px #6e6e6e;

  width: 100%;
  padding-bottom: 4.6vh;
  height: calc(16em + 40vw);
  
  
  display: flex;
  flex-direction: column;
  align-items: baseline;
  justify-content: center;


  @media (min-width: ${mediaSizes.lg}) {
    height: calc(11em + 38vw);
  }
  @media (min-width: ${mediaSizes.xl}) {
    height: calc(11em + 43vw);
  }
  
`;



export const StyledVideoPlayerButton = styled.button`
  width: 1em;
  height: 1em;
  border-radius: 50px;
  border: solid 2px #6e6e6e;
  background-color: transparent;
  margin: 7px 7px;
`;

