import React from "react";

import Frame from "../../assets/images/MAc.png";
import {
  SideScrollbarContainer,
  SideScrollerContentWrapper,
  SideScrollerFrame,
} from "./side-scroller.styles";

const SideScrollerComponent = ({ children }) => {
  return (
    <SideScrollbarContainer>
      <SideScrollerFrame src={Frame} />
      <SideScrollerContentWrapper>{children}</SideScrollerContentWrapper>
    </SideScrollbarContainer>
  );
};

export default SideScrollerComponent;
