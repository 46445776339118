import React from "react";
import { useTranslation } from "react-i18next";

import {
  SectionComponent,
  TitleComponent,
  ContactUsFormComponent,
  ParagraphComponent,
} from "../";

const ContactUsSectionComponent = () => {
  const { t } = useTranslation();

  return (
    <SectionComponent
      zIndex={1}
      id={"section-contact-us"}
    >
      <TitleComponent id="contact_us" content={t("contact_us.title")} />
      <ParagraphComponent>{t("contact_us.email")}</ParagraphComponent>
      <ContactUsFormComponent />
    </SectionComponent>
  );
};

export default ContactUsSectionComponent;
