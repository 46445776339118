import styled from "styled-components";

export const CarouselContainer = styled.div`
  position: relative;
`;

export const CarouselSlide = styled.div`
  position: absolute;
  opacity: ${(props) => (props.isActive ? "1" : "0")};
  transform:${props => props.isActive? 'translateX(0)':props.wasActive?'translateX(-100%)':'translateX(100%)'};
    transition-duration: 500ms;
  
`;
