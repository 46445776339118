import React from "react";

import { FiPaperclip } from "react-icons/fi";
import { StyledAttachFileButton } from "./attach-file-btn.styles";
import { theme } from "../../settings/theme.styles";

const AttachFileBtnComponent = ({
  borderColor = theme.colors.secondary,
  children,
  ...otherProps
}) => {
  return (
    <StyledAttachFileButton borderColor={borderColor} {...otherProps}>
      <FiPaperclip />
      {children}
    </StyledAttachFileButton>
  );
};

export default AttachFileBtnComponent;
