import React, { useEffect, useState } from "react";

import { CarouselContainer, CarouselSlide } from "./carousel.styles";

const CarouselComponent = ({ children }) => {
  const [activeSlide, setActiveSlide] = useState(0);

  useEffect(() => {
    setInterval(() => {
      if (activeSlide + 1 === children.length) {
        setActiveSlide(0);
      } else {
        setActiveSlide(activeSlide + 1);
      }
    }, 5000);
  }, [activeSlide, children.length]);

  return (
    <CarouselContainer>
      {children.map((item, idx) => (
        <CarouselSlide
          key={idx}
          wasActive={
            activeSlide - 1 === idx ||
            (activeSlide === 0 && idx === children.length - 1)
          }
          isActive={activeSlide === idx}
        >
          {item}
        </CarouselSlide>
      ))}
    </CarouselContainer>
  );
};

export default CarouselComponent;
