import React, { useState } from "react";

import { ReactComponent as MobileLogo } from "../../assets/logo/LogoMobileDropMenuVersion.svg";
import { FiMenu, FiX } from "react-icons/fi";
import { theme } from "../../settings/theme.styles";
import {
  DropdownMenuContainer,
  DropdownMenuButton,
  DropdownMenu,
  DropdownMenuItem,
} from "./dropdown-menu.styles";

const DropdownMenuComponent = ({ children }) => {
  const [menuShow, setMenuShow] = useState(false);

  const handleMenuShow = () => {
    setMenuShow(!menuShow);
  };

  return (
    <DropdownMenuContainer>
      <DropdownMenuButton onClick={handleMenuShow}>
        <FiMenu size={"3em"} color={theme.colors.dark} />
      </DropdownMenuButton>
      <DropdownMenu show={menuShow}>
        <MobileLogo />
        <DropdownMenuButton onClick={handleMenuShow}>
          <FiX size={"3em"} color={theme.colors.dark} />
        </DropdownMenuButton>
        {children.map((item, idx) => {
          return (
            <DropdownMenuItem onClick={handleMenuShow} key={idx}>
              {item}
            </DropdownMenuItem>
          );
        })}
      </DropdownMenu>
    </DropdownMenuContainer>
  );
};

export default DropdownMenuComponent;
