import React, { useRef } from "react";
import { useTranslation } from "react-i18next";

import { FormContainer, StyledButton } from "./join-form.styles";

import { AttachFileBtnComponent, CustomTextInputComponent } from "..";
import { theme } from "../../settings/theme.styles";

const JoinUsFormComponent = () => {
  const { t } = useTranslation();

  const hiddenFileInput = useRef(null);

  const handleAttachFileClick = () => {
    hiddenFileInput.current.click();
  };

  return (
    <FormContainer
      action="https://formsubmit.co/ulises.abraham.morfa@gmail.com"
      method="POST"
      encType="multipart/form-data"
    >
      <CustomTextInputComponent
        label={t("contact_us.label_name")}
        name={"name"}
        color={theme.colors.dark}
      />
      <CustomTextInputComponent
        label={t("contact_us.label_phone")}
        name={"phone"}
        color={theme.colors.dark}
      />
      <CustomTextInputComponent
        label={t("contact_us.label_message")}
        name={"text"}
        type={"textarea"}
        color={theme.colors.dark}
      />
      <AttachFileBtnComponent onClick={handleAttachFileClick}>
        Attach file
      </AttachFileBtnComponent>
      <StyledButton type="submit" variant="contained" color="primary">
        <strong>{t("home.CTA_btn")}</strong>
      </StyledButton>

      <input
        name="attachment"
        type="file"
        ref={hiddenFileInput}
        hidden
        required
      />
    </FormContainer>
  );
};

export default JoinUsFormComponent;
