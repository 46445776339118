import React from "react";

import { theme } from "../../settings/theme.styles";
import { StyledTitle } from "./title.styles";

const TitleComponent = ({ content, color = theme.colors.light , ...otherProps}) => {
  return <StyledTitle color={color} {...otherProps}>{content}</StyledTitle>;
};

export default TitleComponent;
