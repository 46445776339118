import React from "react";

import {
  StrategyInfoContainer,
  StrategyInfoImg,
  StrategyMarkerCircle,
  StrategyMarkerContainer,
  StrategyMarkerLine,
} from "./strategy-info.styles";

const StrategyInfoComponent = ({ img, children }) => {
  return (
    <>
      <StrategyMarkerContainer>
        <StrategyMarkerLine />
        <StrategyMarkerCircle />
      </StrategyMarkerContainer>
      <StrategyInfoContainer>
        <StrategyInfoImg src={img} />
        {children}
      </StrategyInfoContainer>
    </>
  );
};

export default StrategyInfoComponent;
