import cookies from "js-cookie";
import React from "react";
import ReactPlayer from "react-player";

import {
  StyledVideoPlayerButton,
  VideoPlayerContainer,
} from "./video-player.styles";

const VideoPlayerComponent = () => {
  const currentLanguage = cookies.get("i18next");

  return (
    <VideoPlayerContainer>
      <div
        style={{
          display: "flex",
          height: "50px",
          paddingLeft: "3%",
        }}
      >
        <StyledVideoPlayerButton />
        <StyledVideoPlayerButton />
        <StyledVideoPlayerButton />
      </div>
      <ReactPlayer
        url={
          `${process.env.PUBLIC_URL}assets/videos/${currentLanguage==='en'?'XodiaPresentationProposalEN.mp4':'XodiaPresentacinES.mp4'}`
        }
        controls={true}
        width={"100%"}
        height={"100%"}
        playsinline={true}
        />
    </VideoPlayerContainer>
  );
};

export default VideoPlayerComponent;
