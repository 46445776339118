import React from "react";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import cookies from "js-cookie";
import { useCustomMediaQuery } from "../../utils/hooks";

import { FiGlobe } from "react-icons/fi";
import { logos } from "../../settings/theme.styles";
import {
  ContactUsButton,
  LanguageButtonText,
  NavBarContainer,
  OptionDiv,
  OptionsContainer,
} from "./nav-bar.styles";

import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import { Button } from "@mui/material";
import { DropdownMenuComponent, LogoContanierComponent } from "../";

const NavBarComponent = () => {
  const { t } = useTranslation();
  const currentLanguage = cookies.get("i18next");
  const isScreenLg = useCustomMediaQuery("md");

  const handleChangeLanguage = () => {
    if (currentLanguage === "en") {
      i18next.changeLanguage("es");
    } else {
      i18next.changeLanguage("en");
    }
  };

  return (
    <NavBarContainer>
      <LogoContanierComponent logoUrl={logos.landing_logo} url={'/home'}/>
      {/* large screen only */}
      <OptionsContainer hidden={!isScreenLg}>
        <HashLink smooth to="/home#benefits">
          <OptionDiv>{t("sections.benefits")}</OptionDiv>
        </HashLink>
        <HashLink smooth to="/home#about_us">
          <OptionDiv>{t("sections.about_us")}</OptionDiv>
        </HashLink>
        <HashLink smooth to="/home#strategy">
          <OptionDiv>{t("sections.strategy")}</OptionDiv>
        </HashLink>
        <HashLink smooth to="/home#services">
          <OptionDiv>{t("sections.services")}</OptionDiv>
        </HashLink>
        <HashLink smooth to="/home#startup">
          <OptionDiv>{t("sections.startups")}</OptionDiv>
        </HashLink>
        <Link to="/jobs">
          <OptionDiv>{t("sections.job")}</OptionDiv>
        </Link>
      </OptionsContainer>

      <OptionsContainer hidden={!isScreenLg}>
        <Button
          onClick={handleChangeLanguage}
          startIcon={<FiGlobe />}
          color="inherit"
        >
          <LanguageButtonText currentLanguage={currentLanguage === "en"}>
            en
          </LanguageButtonText>
          <LanguageButtonText currentLanguage>|</LanguageButtonText>
          <LanguageButtonText currentLanguage={currentLanguage === "es"}>
            es
          </LanguageButtonText>
        </Button>
      </OptionsContainer>
      <OptionsContainer hidden={!isScreenLg}>
        <HashLink smooth to="/home#contact_us">
          <ContactUsButton>{t("nav_bar.contact_us_btn")}</ContactUsButton>
        </HashLink>
      </OptionsContainer>
      {/* small screen only */}
      <OptionsContainer hidden={isScreenLg}>
        <DropdownMenuComponent>
          <HashLink smooth to="/home#home">
            <OptionDiv>{t("sections.home")}</OptionDiv>
          </HashLink>
          <HashLink smooth to="/home#benefits">
            <OptionDiv>{t("sections.benefits")}</OptionDiv>
          </HashLink>
          <HashLink smooth to="/home#about_us">
            <OptionDiv>{t("sections.about_us")}</OptionDiv>
          </HashLink>
          <HashLink smooth to="/home#strategy">
            <OptionDiv>{t("sections.strategy")}</OptionDiv>
          </HashLink>
          <HashLink smooth to="/home#services">
            <OptionDiv>{t("sections.services")}</OptionDiv>
          </HashLink>
          <HashLink smooth to="/home#startup">
            <OptionDiv>{t("sections.startups")}</OptionDiv>
          </HashLink>
          <Link to="/jobs">
            <OptionDiv>{t("sections.job")}</OptionDiv>
          </Link>
          <Button
            onClick={handleChangeLanguage}
            startIcon={<FiGlobe />}
            color="inherit"
          >
            <LanguageButtonText currentLanguage={currentLanguage === "en"}>
              en
            </LanguageButtonText>
            <LanguageButtonText currentLanguage>|</LanguageButtonText>
            <LanguageButtonText currentLanguage={currentLanguage === "es"}>
              es
            </LanguageButtonText>
          </Button>
          <HashLink smooth to="/home#contact_us">
            <ContactUsButton>{t("nav_bar.contact_us_btn")}</ContactUsButton>
          </HashLink>
        </DropdownMenuComponent>
      </OptionsContainer>
    </NavBarContainer>
  );
};

export default NavBarComponent;
