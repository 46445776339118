import React from "react";
import { SpinnerContainer, SpinnerOverlay } from "./spinner.styles";

const SpinnerComponent = () => {
  <SpinnerOverlay>
    <SpinnerContainer />
  </SpinnerOverlay>;
};

export default SpinnerComponent;
