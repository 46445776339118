import styled from "styled-components";


export const StyledAttachFileButton = styled.button`
  background-color: transparent;
  border: 2px solid ${(props) => props.borderColor};
  border-radius: 10px;
  padding: 0.5em 1.5em;
  font-family: ${(props) => props.theme.font.familyNeueMachina};
  font-weight: bolder;
  cursor: pointer;

    display: flex;
    align-items: center;
    justify-content: space-between;
    > :first-child {
        width: 1.2em;
        height: auto;
        margin-right: .6em;
    }
`;
