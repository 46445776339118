import styled from "styled-components";
import { mediaSizes } from "../../settings/theme.styles";

export const FooterContainer = styled.div`
  background-color: #0057ff;

  margin-top: -3px;
  padding-block: 3vh;
  padding-inline: 4vw;
  height: 100vh;

  display: grid;
  gap: calc(1em + 2vw);

  @media (min-width: ${mediaSizes.lg}) {
    height: 50vh;
    padding-block: clamp(0.1em, calc(24vh - 100px), 200em);
    grid-template-columns: 1fr 2fr 1fr;
    padding-inline: clamp(0.1em, calc(18vw - 100px), 200em);

    > :first-child {
      height: fit-content;
      align-items: flex-start;
    }

    > :nth-child(2) {
      grid-row: span 2;
    }
    > :last-child {
      align-items: flex-end;
    }
  }
`;

export const ElementWrapper = styled.div`
  height: auto;
  display: flex;
  align-items: center;
`;

export const OptionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  width: 100%;
  height: 100%;

  > * {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    > * {
      height: fit-content;
    }
    @media (min-width: ${mediaSizes.lg}) {
      flex-direction: row;
      align-items: stretch;
      justify-content: flex-start;

      height: fit-content;
      column-gap: 20px;

      > :first-child {
        width: 50%;
      }
    }
  }
`;

export const OptionDiv = styled.h1`
  padding: 0 0.7vw;
  cursor: pointer;
  height: fit-content;

  color: white;
  font-size: 2.4em;
  font-weight: bolder;
  font-family: ${(props) => props.theme.font.familyNeueMachina};

  width: fit-content;
  white-space: nowrap;
`;
