import styled, { css } from "styled-components";
import { mediaSizes } from "../../settings/theme.styles";

const Height = css`
  height: calc(20vh + 20vw + 6em);
`;

export const SideScrollbarContainer = styled.div`
  display: flex;
  position: relative;
  justify-content: center;
  margin-top: 5rem;

  ${Height}
`;

export const SideScrollerFrame = styled.img`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  width: 100%;
  ${Height}
`;

export const SideScrollerContentWrapper = styled.div`
  z-index: 10;

  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: 45vw;
  gap: 25vw;

  overflow-x: scroll;
  overscroll-behavior-inline: contain;
  scroll-snap-type: inline mandatory;

  ${Height}
  min-width: 90vw;
  padding-inline: 40vw;

  > * {
    scroll-snap-align: center;

    display: flex;
    align-items: center;

    padding-bottom: 4vh;
    font-size: 2em;
  }

  @media (min-width: ${mediaSizes.lg}) {
    > * {
      font-size: 3em;
    }
    
  }
`;
