import { lazy, Suspense } from "react";
import { Navigate, Route, Routes } from "react-router";

import "./App.css";

import { NavBarComponent, FooterComponent, SpinnerComponent } from "./components";
const HomeComponent = lazy(() => import("./views/home/home.component"));
const JobsComponent = lazy(() => import("./views/jobs/jobs.component"));

function App() {
  return (
  

    <div className="App">
      <NavBarComponent />
      <Suspense fallback={<SpinnerComponent />}>
        <Routes>
          <Route path="/home" element={<HomeComponent />} />
          <Route path="/jobs" element={<JobsComponent />} />
          <Route path="/*" element={<Navigate to="/home" replace />} />
        </Routes>
      </Suspense>
      <FooterComponent />
    </div>
  );
}

export default App;
