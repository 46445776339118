import styled from "styled-components";
import { mediaSizes } from "../../settings/theme.styles";

export const DrawerContainer = styled.div``;

export const StyledDrawer = styled.div`
  display: flex;
  justify-content: space-between;

  padding: 0.9em 2em;
  border: 4px solid ${(props) => props.theme.colors.secondary};
  border-radius: 22px;

  > * {
    font-size: 2.5em;
  }
  > :last-child {
    cursor: pointer;
  }
`;

export const ContentWrapper = styled.div`
  background-color: #00142e;
  border-radius: 22px;

  padding: 1em;
  margin-top: 1.5em;
  > * > * {
    color: ${(props) => props.theme.colors.light};
  }

  display: ${(props) => (props.isOpen ? "block" : "none")};

  @media (min-width: ${mediaSizes.md}) {
    padding: 6em;
  }
`;
