const languageDetectionOptions = {
  order: [
    "path",
    "cookie",
    "localStorage",
    "sessionStorage",
    "htmlTag",
    "querystring",
    "navigator",
    "subdomain",
  ],
  caches: ["cookie"],
};
const backendOptions = {
  loadPath: "/assets/locales/{{lng}}/translations.json",
};

export const initOptions = {
  supportedLngs: ["en", "es"],
  fallbackLng: "en",
  detection: languageDetectionOptions,
  backend: backendOptions,
  debug: false,
};
