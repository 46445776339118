import React from "react";

import { SectionContainer } from "./section.styles";

const SectionComponent = ({
  children,
  zIndex,
  backgroundImg=false,
  ...otherProps
}) => {
  return (
    <SectionContainer
      backgroundImg={backgroundImg}
      zIndex={zIndex}
      {...otherProps}
    >
      {children}
    </SectionContainer>
  );
};

export default SectionComponent;
