import React, { useRef } from "react";
import { useTranslation } from "react-i18next";

import AttachFileBtnComponent from "../attach-file-btn/attach-file-btn.component";

import {
  JoinFormPresetContainer,
  StyledButton,
} from "./join-form.preset.styles";

const JoinFormPresetComponent = ({ formMessage }) => {
  const { t } = useTranslation();

  const hiddenFileInput = useRef(null);

  const handleAttachFileClick = () => {
    hiddenFileInput.current.click();
  };

  return (
    <JoinFormPresetContainer
      action={process.env.REACT_APP_CONTACT_EMAIL}
      method="POST"
      encType="multipart/form-data"
    >
      <AttachFileBtnComponent onClick={handleAttachFileClick}>
        Attach file
      </AttachFileBtnComponent>
      <StyledButton type="submit" variant="contained" color="primary">
        <strong>{t("contact_us.submit_btn")}</strong>
      </StyledButton>
      {/* HIDDEN FIELDS */}
      <input name="_subject" value={formMessage} hidden />
      <input
        name="attachment"
        type="file"
        ref={hiddenFileInput}
        hidden
        required
      />
    </JoinFormPresetContainer>
  );
};

export default JoinFormPresetComponent;
